import classNames from "classnames";
import { EyeIcon } from "gx-npm-icons";
import {
  deleteAsyncRequest,
  FreeTrialRole,
  InitState,
  InitUserRole,
  postAsyncRequest,
  useUserState,
  UUID,
} from "gx-npm-lib";
import { PopoverMenu, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { DashBoardAppContext } from "../../app.context";
import { Initiative, InitiativeStateChangeAction } from "../../app.types";
import { getMenuItemByState } from "../../lib";
import ActionDialog from "../../ui/action-dialog/action-dialog.component";
import EvalCardFooter from "./eval-card-footer/eval-card-footer.component";
import styles from "./eval-card-item-v2.module.scss";

type EvalCardItemV2Props = { data: Initiative; isLastCard: boolean; onRemoveInitiative?: (initId: UUID) => void };
const EvalCardItemV2 = ({ data, isLastCard = false, onRemoveInitiative = () => {} }: EvalCardItemV2Props) => {
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [permanentDeleteDialog, setPermanentDeleteDialog] = useState(false);
  const { templateName, id, name, products, stateUpdateDate, status, lastActivity } = data;
  const {
    setShowSnackBar,
    setSnackbarMessage,
    updateStateProcessing,
    setUpdateStateProcessing,
    setUserInitiatives,
    setSaveDataErrorStatus,
    currentState,
  } = useContext(DashBoardAppContext);
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);

  const isOwner = data.userRole === InitUserRole.OWNER;
  const isViewOnly = data.userRole === InitUserRole.VIEWER;
  const titleDisplayValue = name || t("Untitled evaluation");
  const menuItems = getMenuItemByState(currentState);
  const navigateToOverview = () => navigateToUrl(`/s/evaluation/${id}/overview`);

  const handleInitiativeRestore = async () => {
    setUpdateStateProcessing(true);
    setSnackbarMessage(`${titleDisplayValue} has been restored.`);
    const response = await postAsyncRequest(`/api/v2/initiatives/${id}/action/active`);
    if (response.status === 201) {
      setShowSnackBar(true);
      setUserInitiatives((prev) => prev.reduce((acc, curr) => (curr.id === id ? acc : [...acc, curr]), []));
    } else {
      setSaveDataErrorStatus(true);
    }
    setUpdateStateProcessing(false);
  };

  const handleInitiativeArchive = async () => {
    setUpdateStateProcessing(true);
    setSnackbarMessage(`${titleDisplayValue} was sent to archive.`);
    const response = await postAsyncRequest(`/api/v2/initiatives/${id}/action/archive`);
    if (response.status === 201) {
      setShowSnackBar(true);
      onRemoveInitiative(id);
    } else {
      setSaveDataErrorStatus(true);
    }
    setUpdateStateProcessing(false);
  };

  const handleMenuOptionClick = async (e: MouseEvent<HTMLButtonElement>) => {
    const eventIndex = e as unknown as number;
    const selectedItem = menuItems?.find(({ index }) => index === eventIndex);
    const { action } = selectedItem || {};
    if (action === InitiativeStateChangeAction.PERMANENTLY_DELETE) {
      setPermanentDeleteDialog(true);
    } else if (action === InitiativeStateChangeAction.DELETE) {
      setDeleteDialog(true);
    } else if (action === InitiativeStateChangeAction.RESTORE) {
      await handleInitiativeRestore();
    } else if (action === InitiativeStateChangeAction.ARCHIVE) {
      await handleInitiativeArchive();
    }
  };

  const handleConfirmDelete = async () => {
    setUpdateStateProcessing(true);
    setSnackbarMessage(`${titleDisplayValue} was sent to trash.`);
    const response = await postAsyncRequest(`/api/v2/initiatives/${id}/action/delete`);
    if (response.status === 201) {
      setShowSnackBar(true);
      setDeleteDialog(false);
      onRemoveInitiative(id);
    } else {
      setSaveDataErrorStatus(true);
    }
    setUpdateStateProcessing(false);
  };

  const handleConfirmPermanentDeleteDialog = async () => {
    setUpdateStateProcessing(true);
    setSnackbarMessage(`${titleDisplayValue} has been permanently deleted.`);
    const response = await deleteAsyncRequest(`/api/v2/initiatives/${id}`);
    if (response.status === 200) {
      setShowSnackBar(true);
      setPermanentDeleteDialog(false);
      setUserInitiatives((prev) => prev.reduce((acc, curr) => (curr.id === id ? acc : [...acc, curr]), []));
    } else {
      setSaveDataErrorStatus(true);
    }
    setUpdateStateProcessing(false);
  };

  let kebabMenuTooltipText = t("Evaluation options");
  if (isTrialUser) {
    kebabMenuTooltipText = t("Archive and deleting is not available in the Gartner BuySmart free trial.");
  } else if (!isOwner) {
    kebabMenuTooltipText = t("This action is only available to the evaluation owner.");
  }
  return (
    <div className={styles.evalCardWrapper}>
      <button className={styles.overviewLink} aria-label={t("click for overview")} onClick={navigateToOverview}>
        <div className={classNames(styles.container, isLastCard && "is-last")}>
          <div className={styles.templateWrapper}>
            <TypographyComponent rootClassName={styles.templateName} styling="p4">
              {templateName}
            </TypographyComponent>
            {isViewOnly && (
              <div className={classNames(styles.viewOnly, !templateName && styles.noTemplateName)}>
                <EyeIcon width="20" height="18" />
                <TypographyComponent rootClassName={styles.viewOnlyText} styling="p4">
                  {t("View only")}
                </TypographyComponent>
              </div>
            )}
          </div>
          <div
            className={classNames(styles.title, !templateName && styles.noTemplate, isViewOnly && styles.viewOnlyTitle)}
          >
            <TypographyComponent boldness="medium" rootClassName={styles.titleWrap} styling="p1">
              {titleDisplayValue}
            </TypographyComponent>
          </div>
          <EvalCardFooter
            products={products}
            status={status}
            stateUpdateDate={stateUpdateDate}
            initState={currentState}
            lastActivity={lastActivity}
          />
        </div>
      </button>
      <TooltipV2
        data-testid="menu-icon"
        enterNextDelay={300}
        placement={isTrialUser ? "right" : "top"}
        PopperProps={{ modifiers: { offset: { offset: "0, 5px" } } }}
        title={kebabMenuTooltipText}
      >
        <div className={classNames(styles.stateOptionWrapper, currentState === InitState.DELETED && styles.deleted)}>
          <PopoverMenu
            disabled={!isOwner || isTrialUser}
            iconType={"kebab"}
            menuItems={menuItems}
            onClick={handleMenuOptionClick}
            rootClassName="gx-initiative-states-item"
            showSelectedOption={false}
            useIconButton={true}
            alignmentType={"left"}
          />
        </div>
      </TooltipV2>
      <ActionDialog
        displayName={titleDisplayValue}
        handleClose={() => setDeleteDialog(false)}
        handleConfirm={handleConfirmDelete}
        isLoading={updateStateProcessing}
        isOpen={deleteDialog}
        textBodyEnd={t("? You can always restore this evaluation if you change your mind.")}
        textBodyStart={t("Are you sure you want to delete")}
        textButtonCancel={t("CANCEL")}
        textButtonConfirm={t("CONFIRM")}
        textTitle={t("Delete evaluation")}
      />
      <ActionDialog
        confirmTextValue={"delete"}
        displayConfirmTextbox={true}
        displayName={titleDisplayValue}
        handleClose={() => setPermanentDeleteDialog(false)}
        handleConfirm={handleConfirmPermanentDeleteDialog}
        isLoading={updateStateProcessing}
        isOpen={permanentDeleteDialog}
        textBodyEnd={t('? Type "Delete" to confirm you want to delete this evaluation.')}
        textBodyStart={t("Are you sure you want to permanently delete")}
        textButtonCancel={t("CANCEL")}
        textButtonConfirm={t("PERMANENTLY DELETE")}
        textConfirmLabel={t('Type "Delete" to confirm')}
        textTitle={t("Permanently delete evaluation")}
      />
    </div>
  );
};

export default EvalCardItemV2;
