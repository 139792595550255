import React, { Fragment, useState, useEffect } from "react";
import { handleEvent } from "gx-npm-lib";
import { Button, ButtonLoader, Dialog, TextField, TypographyComponent } from "gx-npm-ui";
import styles from "./action-dialog.module.scss";

type ActionDialogProps = {
  confirmTextValue?: string;
  displayConfirmTextbox?: boolean;
  displayName?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
  textBodyEnd?: string;
  textBodyStart?: string;
  textButtonCancel?: string;
  textButtonConfirm?: string;
  textConfirmLabel?: string;
  textTitle?: string;
};

const ActionDialog = ({
  confirmTextValue = "",
  displayConfirmTextbox = false,
  displayName = "",
  handleClose = () => {},
  handleConfirm = () => {},
  isLoading = false,
  isOpen = false,
  textBodyEnd = "",
  textBodyStart = "",
  textButtonCancel = "",
  textButtonConfirm = "",
  textConfirmLabel = "",
  textTitle = "",
}: ActionDialogProps) => {
  const [confirmText, setConfirmText] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(displayConfirmTextbox);

  const handleTextboxChange = (e: { target: { value: string } }) => {
    handleEvent(setConfirmText, e.target.value);
  };

  useEffect(() => {
    const value =
      displayConfirmTextbox &&
      !(confirmText && confirmTextValue && confirmText.toLowerCase() === confirmTextValue.toLowerCase());
    setConfirmDisabled(value);
  }, [confirmText, confirmTextValue, displayConfirmTextbox]);

  const closeHandler = () => {
    setConfirmDisabled(displayConfirmTextbox);
    setConfirmText("");
    handleEvent(handleClose);
  };

  return (
    <Dialog
      open={isOpen}
      handleClose={closeHandler}
      title={textTitle}
      body={
        <Fragment>
          <TypographyComponent element={"span"} rootClassName={styles.dialogText}>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"}>
              {textBodyStart}
            </TypographyComponent>
            <TypographyComponent
              element={"span"}
              styling={"p2"}
              boldness={"medium"}
              color={"coal"}
            >{` ${displayName}`}</TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"}>
              {textBodyEnd}
            </TypographyComponent>
          </TypographyComponent>
          {displayConfirmTextbox && (
            <TextField fullWidth label={textConfirmLabel} onChange={handleTextboxChange} value={confirmText} />
          )}
        </Fragment>
      }
      footer={
        <div style={{ display: "flex", float: "right" }}>
          {!!handleClose && (
            <Button onClick={closeHandler} rootClassName="btn-tertiary">
              {textButtonCancel}
            </Button>
          )}
          {!!handleConfirm && (
            <div style={{ marginLeft: 24 }}>
              <ButtonLoader
                btnClass={confirmDisabled ? "btn-primary" : "primary-destructive-btn"}
                disabled={confirmDisabled}
                handleButtonClick={() => handleEvent(handleConfirm)}
                isLoading={isLoading}
              >
                {textButtonConfirm}
              </ButtonLoader>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ActionDialog;
